import React from 'react'
import HomePage from '@/pages/home'
import FooterPage from '@/pages/footer'

export const Pages:React.FC = () => {
  return (
    <>

      <HomePage />
      <FooterPage />
    </>
  )
}

