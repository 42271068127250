import styled from 'styled-components'
import { Button } from 'primereact/button'
import { Menubar } from 'primereact/menubar'

export const CustomButton = styled(Button)`
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  border: 2px rgba(255, 255, 255, 0.8) solid;
  font-family: PlusJakarta-bold,sans-serif;
  height: 40px;
  font-size: 14px;
  padding: 4px 6px;

`

export const WalletMenubar = styled(Menubar)`
  font-family: PlusJakarta-bold, sans-serif;
  background-color: transparent;
  border: none;
  padding: 0;


  .p-submenu-list {
    background-color: rgb(28, 28, 28);
    border-radius: 8px;

  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #ffffff;
    background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
    border-radius: 8px;

  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #000000;
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #000000;
  }
  
  @media screen and (max-width: 960px) {
    
     .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    }

    .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: -120px !important;
      z-index: 1;
   }

    .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.25rem 0;
      background: none;
      border: none;
      border-radius: 8px;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
      width: 180px;
    }
  }
 

 
`

