import React, { useEffect } from 'react'
import './App.css'
import './assets/fonts/font.css'
import { Route, Routes } from 'react-router-dom'
import { Pages } from '@/pages'
import { useEagerConnect } from '@/hooks/utils/useEagerConnect'
import { useWeb3React } from '@web3-react/core'
import { DEFAULT_NETWORK_CONFIG, networkConf, supportWallets, WalletType } from '@/web3/connectors'

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import Marketplace from '@/pages/marketplace'
import Navbar from '@/pages/navbar'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAVPykrW1Ha5qnGEcWUEzPD7_mzktroMFE',
  authDomain: 'symswap-frontend.firebaseapp.com',
  projectId: 'symswap-frontend',
  storageBucket: 'symswap-frontend.appspot.com',
  messagingSenderId: '432130820107',
  appId: '1:432130820107:web:781d29e25613e6bbf25f0e',
  measurementId: 'G-MS96WETEKL'
}

// Initialize Firebase

function App() {
  const { account, activate } = useWeb3React()
  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)

  useEffect(() => {
    initializeApp(firebaseConfig)

  }, [])

  useEffect(():any => {
    const { ethereum } = window as any
    if (ethereum  && account) {
      ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: DEFAULT_NETWORK_CONFIG.chainId }]
      }).catch((e: any) => {
        if (e.code === 4902) {
          ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...DEFAULT_NETWORK_CONFIG
              }
            ],
          })
        }
      })
    }

  },[ account])

  useEagerConnect()

  // useEffect(() => {
  //   const provider = window.localStorage.getItem('provider')
  //   const wallet = supportWallets.find(wallet => wallet.name === provider)
  //   console.log(wallet)
  //   if (wallet && (provider !=='undefined'))
  //     activate(wallet.connector)
  // }, [])

  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <Routes>
          <Route path={'/'} element={<Pages />} />
          {/*<Route path={'/marketplace'} element={<Marketplace />} />*/}
        </Routes>
      </header>
    </div>
  )
}

export default App
