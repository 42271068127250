import React from 'react'
import { FooterContainer } from '@/pages/footer/index.style'
import Discord from '@/assets/images/icon_discord.png'
import Twitter from '@/assets/images/icon_twitter.png'
import Github from '@/assets/images/icon_github.png'
import { Button } from 'primereact/button'
import { getAnalytics, logEvent,setUserProperties, } from 'firebase/analytics'

const EXTERNAL_LINKS: Array<{ icon?: any, link: string }> = [
  // { icon: Discord, link: '1' },
  { icon: Twitter, link: 'https://twitter.com/symswap' },
  { icon: Github,  link:'https://github.com/Symswap' },
]

const FooterPage:React.FC = () => {
  const analytics = getAnalytics()

  const handleLinkClicks = (item: any) => {
    window.open(item.link,'_blank')
    logEvent(analytics,'select_item',{ description: item.link })
  }

  return (
    <FooterContainer >

      <div className={'opacity-20 border-t-[#ffffff] border w-[100%] md:w-[90%] mb-10 '} />
      <div className={'justify-between flex flex-col md:flex-row max-w-[1360px]  w-full md:w-[90%] p-2  mb-10 items-center md:items-start '}>
        <div className={' items-center flex flex-col  w-[80%]  gap-4 md:w-[60%] md:items-start'}>
          <div className={' flex flex-col md:flex-row items-center  md:items-start w-full gap-3  md:gap-8' }>
            {/*<a>Docs</a>*/}
            {/*<a target={'_blank'} rel={'noreferrer'} href={'https://github.com/'}>API</a>*/}
            {/*<a target={'_blank'} rel={'noreferrer'}>Governance</a>*/}
            {/*<a target={'_blank'} rel={'noreferrer'}>Blog</a>*/}
            {/*<a target={'_blank'} rel={'noreferrer'}>FAQ</a>*/}
            {/*<a target={'_blank'} rel={'noreferrer'}>Contact</a>*/}
          </div>
          <span className={'mb-5'}>@2022 Symswap-All right reserved</span>
        </div>

        <div className={'items-end justify-end flex  items-center gap-7 w-[35%] '}>
          {
            EXTERNAL_LINKS.map(item => (
              <div className={'cursor-pointer'}  key={item.link} onClick={() => handleLinkClicks(item)}>
                <img className={'object-contain'} src={item.icon} alt={item.link} />
              </div>
            ))
          }
        </div>
      </div>
    </FooterContainer>
  )
}

export default FooterPage
