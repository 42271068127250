import styled from 'styled-components'
import { Dropdown } from 'primereact/dropdown'

export const MAX_WIDTH = 1360

export const GradientText = styled('div')<{fontSize?:number}>`
  background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-family: PlusJakarta-bold;
`

export const CustomInput = styled('div')`

  .p-inputtext {
    width: 100%;
    background-color: rgba(36, 36, 36, 1);
    height: 100%;
  }

  .p-inputtext:enabled:hover {
    border-color: #BBE95D;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
  }
`

export const CustomDropdown = styled(Dropdown)`
  font-weight: bold;
  background-color: rgba(36, 36, 36, 1);
height: 100%;
display: flex; 
  align-items: center;
  justify-content: center;
  
`
