import { AbstractConnector } from '@web3-react/abstract-connector'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import MetamaskIcon from '@/assets/images/wallet_metamask.svg'
import CoinBaseIcon from '@/assets/images/wallet_coinbase.webp'

import WalletConnect from '@/assets/images/wallet_walletConnect.svg'

export const injected = new InjectedConnector({})

export const RPC_URLS = {
  5: 'https://eth-goerli.g.alchemy.com/v2/3Z35yGPq2WYTU7ZH73XTEcKxE3a6lFjI',
  1: 'https://eth-mainnet.g.alchemy.com/v2/5CPKXUc2powZTNad0mAV2aWeQaUgqV9i'
}

export const networkConf = {
  5: {
    chainId: '0x' + parseInt('5').toString(16),
    chainName: 'Goerli Testnet Network',
    nativeCurrency: {
      name:'Testnet Network',
      symbol:'ETH',
      decimals:18,
    },
    rpcUrls: [
      'https://eth-goerli.g.alchemy.com/v2/3Z35yGPq2WYTU7ZH73XTEcKxE3a6lFjI',
    ],
    blockExplorerUrls: [RPC_URLS[5]]
  },
  1: {
    chainId: '0x' + parseInt('1').toString(16),
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name:'Ethereum Mainnet',
      symbol:'ETH',
      decimals:18,
    },
    rpcUrls: [
      'https://eth-mainnet.g.alchemy.com/v2/5CPKXUc2powZTNad0mAV2aWeQaUgqV9i'
    ],
    blockExplorerUrls: [RPC_URLS[1]]
  },

}

export const DEFAULT_RPC = 1
export const DEFAULT_NETWORK_CONFIG = networkConf[DEFAULT_RPC]

export type WalletType = {
  name: string
  icon: string,
  connector: AbstractConnector,
}

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 5:RPC_URLS[5] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
})

// export const trezor = new TrezorConnector({
//   chainId: 1,
//   url: RPC_URLS[1],
// )}

export const walletlink = new WalletLinkConnector({
  appName: 'symswap',
  url: RPC_URLS[DEFAULT_RPC],

})

export const supportWallets: WalletType[] = [
  {
    name: 'MetaMask',
    icon: MetamaskIcon,
    connector: injected,
  },
  {
    name: 'Wallet Connect',
    icon: WalletConnect,
    connector: walletconnect,
  },
  {
    name:'Wallet Coin',
    icon: CoinBaseIcon,
    connector: walletlink
  }

]

export function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}
