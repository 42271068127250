import styled from 'styled-components'

export const FooterContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PlusJakarta-bold;
  font-size: min(max(14px, 1.4vw), 18px);

  a {
    cursor: pointer;
    background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    line-height: 21px;
    
  }

  span {
    font-family: PlusJakarta-medium;
    color: rgba(255, 255, 255, 0.6);
    font-size: min(max(14px, 1.2vw), 16px);
    line-height: 21px;

   
  }
`
