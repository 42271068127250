import React, { PropsWithChildren } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ModalProvider } from '../contexts/modal'
import { RefreshControllerProvider } from '@/contexts/refresh-controller'
import { Web3ReactProvider } from '@web3-react/core'
import { getLibrary } from '@/web3/connectors'
import { ProSidebarProvider } from 'react-pro-sidebar'

const queryClient = new QueryClient()

export const Providers:React.FC<PropsWithChildren> = ({ children }) => {

  return (
    <QueryClientProvider client={queryClient}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <RefreshControllerProvider>
          <Router>
            <ModalProvider>
              <ProSidebarProvider>
                {children}
              </ProSidebarProvider>
            </ModalProvider>
          </Router>
        </RefreshControllerProvider>
      </Web3ReactProvider>
    </QueryClientProvider>
  )
}

