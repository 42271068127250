import React, { useState } from 'react'
import SymsLogo from '@/assets/images/syms_logo.png'
import { InputText } from 'primereact/inputtext'
import { CustomButton, WalletMenubar } from '@/pages/navbar/index.style'
import styled from 'styled-components'
import { useModal } from '@/contexts/modal'
import WalletSelector from '@/modals/walletSelector'
import { shortenAddress } from '@/utils'
import { useWeb3React } from '@web3-react/core'
import { CustomInput, MAX_WIDTH } from '@/pages/common.style.'
import { useLocation } from 'react-router-dom'
import { Tooltip } from 'primereact/tooltip'

const Container = styled('div')<{inHome: boolean}>`
  width: 100%; 
  //position: absolute;
  z-index: 1; 
  position: fixed;
  background-color: ${({ inHome }) => inHome ? 'rgba(0,0,0,0.8)' : '#000'};
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(16px);
  
`

const WalletMenu: React.FC = ()=> {

  const { account, deactivate } = useWeb3React()

  const disconnect = () => {
    window.localStorage.setItem('provider', 'undefined')
    deactivate()
  }
  const items = [
    {
      label: shortenAddress(account?.toString()),
      // icon:'pi pi-fw pi-pencil',
      items:[
        {
          label:'Disconnect',
          icon:'pi pi-fw pi-align-left',
          command:disconnect,
        },
      ]
    },
  ]

  return (
    <>
      <WalletMenubar model={items} />
    </>
  )
}

const Navbar:React.FC = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const { openModal } = useModal()
  const { account, active } = useWeb3React()
  const { pathname } = useLocation()

  return (
    <Container inHome={pathname === '/'}>
      <div className={`w-full max-w-[${MAX_WIDTH}px] flex items-center justify-between my-4 relative`}>
        <div className={'w-[20%]'}>
          <img className={'min-w-[120px]'} src={SymsLogo} />
        </div>
        <div className={'flex items-center h-full w-[80%] gap-2 sm:gap-12 justify-end '}>
          {/*<input placeholder={'Test functionality by typing \'new\''} className={'p-2 rounded-md h-8 bg-[#ffffff] bg-opacity-20 w-[40%]'} />*/}
          <CustomInput className={'hidden md:flex w-[40%]'} >
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText className={'p-inputtext-sm'}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder=""
              />
            </span>
          </CustomInput>
          <Tooltip target={'.explore'} position={'top'}> Coming soon </Tooltip>
          <div className={ 'font-sans cursor-pointer explore' }>Explore</div>
          {
            !active  ? (
              <CustomButton  onClick={() => openModal(<WalletSelector />)}>Connect wallet</CustomButton>
            ):
              <WalletMenu />

          }
        </div>
      </div>
    </Container>

  )
}

export default Navbar

