import React, { PropsWithChildren } from 'react'
import { useModal } from '../../modal'
import styled from 'styled-components'

export type DialogProps = {
    title?: string | JSX.Element
    titlePrefix?: React.ReactElement
    footer?: string | JSX.Element
    cancelButtonProps?: any
    confirmButtonProps?: any
    onConfirm?: () => void
    onCancel?: () => void
    bottomMessage?: string | any
    closeable?: boolean
    variant?: DialogVariantProps | 'info'
    showVariantFace?: boolean
}

export type DialogVariantProps = 'success' | 'warning' | 'info' | 'error'

const getDialogVariant = ({ theme, variant }: any) => {
  switch (variant) {
  case 'success':
    return {
      border: `2px solid ${theme.palette.success.main}`,
    }

  case 'warning':
    return {
      border: `2px solid ${theme.palette.warning.main}`,
    }

  case 'error':
    return {
      border: `2px solid ${theme.palette.error.main}`,
    }

  case 'info':
    return {
      border: `2px solid ${theme.palette.info.main}`,
    }

  default: {
    return  {
      border: `2px solid ${theme.palette.info.main}`,
    }
  }
  }
}

const getHeaderBottomVariant = ({ theme, variant }: any) => {
  switch (variant) {
  case 'success' : {
    return {
      borderBottom: `2px solid ${theme.palette.success.main}`,
    }
  }
  case 'error' : {
    return {
      borderBottom: `2px solid ${theme.palette.error.main}`,
    }
  }
  case 'warning' : {
    return {
      borderBottom: `2px solid ${theme.palette.warning.main}`,
    }
  }
  case 'info' : {
    return {
      borderBottom: `2px solid ${theme.palette.info.main}`,
    }
  }

  default: {
    return  {
      borderBottom: `2px solid ${theme.palette.info.main}`,
    }
  }
  }
}

const Wrapper = styled('div')<{variant?: DialogVariantProps}>`
  min-width: 350px;
  border-radius: .5rem;
  position: relative;
  background-color: rgb(0,0,0);
  max-width: 90vw;
`

const Title = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  width: 100%;

  padding: 10px 18px;
  color: #cecece;

`

const Header = styled('div')<{variant?: DialogVariantProps}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  position: relative;
  
`

const CloseButton = styled('div')`
  cursor: pointer;
  position: absolute;
  right: 10px;
  i {
    font-size: 18px;
    color: #cecece;
  }
`

const Footer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 30px;
  padding: 10px 0;
`

const Content = styled('div')` 
  padding: 28px 20px;
`

const Dialog: React.FC<DialogProps & PropsWithChildren> =({
  title,
  titlePrefix,
  footer,
  cancelButtonProps,
  confirmButtonProps,
  onConfirm,
  onCancel,
  bottomMessage,
  closeable,
  children,
  variant,
  ...rest
}) => {

  const { closeModal } = useModal()
  return (
    <Wrapper variant={variant}>
      {
        title && (
          <Header variant={variant}>
            <Title>
              {title}
            </Title>
            {
              closeable && (
                <CloseButton onClick={closeModal} >
                  <i className="pi pi-times"  />
                </CloseButton>
              )
            }

          </Header>
        )
      }
      {
        (!title && closeable) && (
          <CloseButton onClick={closeModal} >
            <i className="pi pi-times" />
          </CloseButton>
        )

      }
      <Content>
        {children}

      </Content>
      {
        (onCancel || onConfirm) && (
          <Footer >
            {
              onCancel && (
                <button onClick={onCancel} >
                  {cancelButtonProps?.children || 'Cancel'}
                </button>
              )
            }
            {
              onConfirm && (
                <button onClick={onConfirm} >
                  {confirmButtonProps?.children || 'Submit'}
                </button>
              )
            }
          </Footer>
        )
      }

    </Wrapper>
  )
}

export default Dialog
