import { AbstractConnector } from '@web3-react/abstract-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

export const shortenAddress = (address?: string, length = 4) => {
  return address ? `${address.substring(0, length)}...${address.slice(-length)}` : undefined
}

export function stringToHex(text: string) {
  const encoder = new TextEncoder()
  const encoded = encoder.encode(text)
  return Array.from(encoded, i => i.toString(16).padStart(2, '0')).join('')
}

export async function sleep(milliseconds: number) {
  return await new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const resetWalletConnector = (connector: AbstractConnector) => {
  if (
    connector &&
      connector instanceof WalletConnectConnector
  ) {
    connector.walletConnectProvider = undefined
  }
}
