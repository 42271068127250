import React, { useCallback, useEffect } from 'react'
import Dialog from '@/contexts/uiKits/Dialog/index'
import { useModal } from '@/contexts/modal'

import {  supportWallets, WalletType } from '@/web3/connectors'
import { useWeb3React } from '@web3-react/core'
import { getAnalytics, logEvent } from 'firebase/analytics'

const WalletSelector:React.FC = () => {
  const { closeModal } = useModal()
  const { activate,account, deactivate } = useWeb3React()
  const analytics = getAnalytics()

  const setProvider = (type: string) => {
    window.localStorage.setItem('provider', type)
  }
  // const [, setLocalStoredWallet] = useLocalStorage<SUPPORT_WALLET_NAMES>(LOCAL_STORAGE_WALLET_KEY)

  const handleWalletConnect = useCallback(
    async (wallet: WalletType) => {
      if (wallet.connector ) {
        await activate(wallet.connector)
        logEvent(analytics,'select_item',{ description:'wallet connect' })

        setProvider(wallet.name)
        closeModal()
      }

      // else window.open(wallet.adapter.url, '_blank')
    },
    [activate],
  )

  return (
    <Dialog title={'Connect Wallet'} closeable>
      {
        supportWallets.map((wallet,index) => (
          <div
            onClick={() => handleWalletConnect(wallet)}
            key={index}
            className={'w-full flex items-center justify-between cursor-pointer bg-transparent hover:bg-[#292524] transition px-4 py-5 rounded-md '}
          >
            <div className={'w-full flex items-center'}>
              <img className={'w-10 mr-4'} src={wallet.icon} />
              <p className={'text-white text-lg font-semibold'}>{wallet.name}</p>
            </div>
            <div className={'flex items-center gap-3'}>
              {/*{*/}
              {/*  wallet.readyState === 'Installed' &&  <Tag>Detected</Tag>*/}
              {/*}*/}
              <i className={'pi pi-chevron-circle-right '} style={{ fontSize:'1.4rem', color:'#cecece' }} />
            </div>
          </div>
        ))
      }
    </Dialog>
  )
}

export default WalletSelector
