import React from 'react'
import InvisibleBackground from '@/assets/images/home_invisible_background.png'
import Background from '@/assets/images/home_background.png'
import VerticalBackground from '@/assets/images/home_background_vertical.png'

import Ring from '@/assets/images/home_ring.png'
import Ball from '@/assets/images/home_ball.png'

import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { AlchemyProvider } from '@/web3/provider'
import { useNavigate } from 'react-router-dom'
import  { Tooltip } from 'primereact/tooltip'

const BackgroundContainer = styled('div')`
  width: 100%;
  height: 100%;
  background: url(${Background}) no-repeat right;
  background-size: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .ball-with-ring {
    position: absolute;
    right: 9.3%;


    img {
      width: 23vw; 
      max-width: 575px;
      object-fit: contain;
    }
  }

  .item-ring {
    position: absolute;
    right: 6.5%;
    width: 29vw; 
    
    -webkit-animation: spin 18s linear infinite;
    -moz-animation: spin 18s linear infinite;
    animation: spin 18s linear infinite;
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(-360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(-360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
      }
    }

    img {
      width: 29vw;
      object-fit: contain;
    }
  }
  
  .dot-one {
    position: absolute;
    right: 6%;
    top: 32%;
    background: rgb(143,239,134);
    border-radius: 50%;
    opacity: .86;
    animation: pulse 2s linear infinite;
    animation-delay: 4s;


    @keyframes pulse {
      0%{
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(187,233,93,0.6), 0 0 0 0 rgba(187,233,93,0.3);
      }
      40%{
        transform: scale(.9);
        box-shadow: 0 0 0 26px rgba(187,233,93,0), 0 0 0 14px rgba(187,233,93,0);
      }
      80%{
        transform: scale(.7);
        box-shadow: 0 0 0 26px rgba(187,233,93,0), 0 0 0 14px rgba(187,233,93,0);
      }
      100%{
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(187,233,93,0), 0 0 0 10px rgba(187,233,93,0);
      }
    }
  }

  .dot-two{
    position: absolute;
    right: 30%;
    top: 50%;
    background-color: rgb(109,241,162);
    border-radius: 50%;
    opacity: .86;
    animation: pulse 4s linear infinite;


    @keyframes pulse {
      0%{
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(63,173,129,0.6), 0 0 0 0 rgba(163,173,129,0.3);
      }
      40%{
        transform: scale(.9);
        box-shadow: 0 0 0 26px rgba(63,173,129,0), 0 0 0 14px rgba(63,173,129,0);
      }
      80%{
        transform: scale(.7);
        box-shadow: 0 0 0 26px rgba(63,173,129,0), 0 0 0 14px rgba(63,173,129,0);
      }
      100%{
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(63,173,129,0), 0 0 0 10px rgba(63,173,129,0);
      }
    }
  }
  }

`

const MobileBackgroundContainer = styled('div')`
  width: 100%;
  height: 100%;
  background: url(${VerticalBackground}) no-repeat 30%; 
  background-size: cover;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

`

const BigTitle = styled('div')`
  font-family: PlusJakarta-bold, "Script MT Bold";
  font-size: min(max(36px, 5vw), 80px);
  white-space: nowrap;
  letter-spacing: 0.01em;
  background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent
`

const SubTitle = styled('div')`
  font-family: PlusJakarta-medium, sans-serif;
  color:  rgba(255, 255, 255, 0.6);
  font-size: min(max(18px, 2vw), 24px);
  line-height: 40px;
  letter-spacing: 0.01em;
 
  
  span {
    background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent
  }
`

const Describe = styled('div')`
  max-width: 574px;
  text-align: left;
  font-family: PlusJakarta-light, sans-serif;
  font-size: min(max(16px, 1.4vw), 20px);
  line-height: 30px;
  letter-spacing: 0.05em;

  color: rgba(255, 255, 255, 0.5);

  span {
    background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent
  }
`

const ButtonGroup = styled('div')`
  color: #000000;
  height: 48px;
  width: 100%;
  display: flex;
  gap: 3rem;
  font-family: PlusJakarta-medium;


  button{
    max-height: 48px;
    max-width: 160px;
    height: 100%;
    width: 100%;  
  }

  .btn-container {
    display: block;
    font-size: min(max(14px, 1.4vw), 18px);
    font-weight: bold;
    text-align: center;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    background-position: top center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    transition-property: background, color;
  }


  .explore-button {
    position: relative;
    background-image: linear-gradient(90deg, #BBE95D, #52FDC9, #52FDC9, #cdf57d, #BBE95D, #52FDC9);
    background-size: 400%;
    background-position: 0% 0%;
   

    &::before {
      content: '';
      position: absolute;
      left: -2px;
      top: -2px;
      right: -2px;
      bottom: -2px;
      background-image: linear-gradient(90deg, #BBE95D, #52FDC9, #52FDC9, #cdf57d, #BBE95D, #52FDC9);
      background-size: 500%;
      background-position: 0% 0%;
      filter: blur(10px);
      opacity: 0;
      z-index: -1;
      transition: opacity 0.2s;
    }

    &:hover {
      animation: gradientRotate 2s infinite;

      &::before {
        opacity: 1;
        animation: gradientRotate 2s infinite;
      }
    }

    &:active {
      color: #000000;
    }

    &:focus {
      &::before {
        opacity: 1;
      }
    }
  }

  .btn-gradient-border {
    height: 100%;
    color: #ffffff;
    border: 2px double transparent;
    background-image: linear-gradient(#000000, #000000), radial-gradient(circle at left top, #BBE95D, #52FDC9);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    
    span {
      background: linear-gradient(225deg, #BBE95D 0%, #52FDC9 81.77%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-fill-color: transparent
    }
  }

  .btn:hover {
    transition: all 0.3s ease;
  }

  .btn-glow:hover {
    box-shadow: rgba(58,171,132, 0.9) 0px 0px 20px 0px;
  }

  @keyframes gradientRotate {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`

const AnimatedDots = styled('div')`

  //border: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  

`

const HomePage:React.FC = () => {
  const navigator = useNavigate()
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1225px)' })

  return (
    <div className={'w-full overflow-hidden'}>
      {
        isDesktopOrLaptop ? (
          <div className={'w-full h-screen relative '}>
            <BackgroundContainer>
              <div className={'ball-with-ring'}>
                <img src={Ball} />
              </div>

              <div className={'item-ring'}>
                <img src={Ring} />
              </div>
              <div className={'dot-one'}><AnimatedDots /></div>
              <div className={'dot-two'}><AnimatedDots /></div>

              <div className={' h-3/6 w-5/6 max-w-[1360px] flex flex-col items-start justify-center '}>
                <BigTitle className={'mb-[32px]'}> Symswap Protocol </BigTitle>
                <SubTitle className={'mb-[40px]'}>Trade NFT by <span> Progressive matching</span> </SubTitle>
                <Describe>Symswap is a decentralized <span>NFT AMM</span> based on Progressive price matching for maximizing assets liquidity</Describe>

                <div className={'flex h-[50px] w-[80%] mt-[80px]'}>
                  <ButtonGroup >
                    <Tooltip target={'.explore'} position={'top'}> Coming soon </Tooltip>
                    <button  className={'btn-container explore-button rounded-lg explore'}>Explore</button>
                    <button className="btn btn-gradient-border btn-glow  rounded-lg"><span>Documents</span></button>
                    <span className="pulse-button">pulse</span>
                  </ButtonGroup>
                </div>
              </div>

            </BackgroundContainer>
          </div>
        ) :
          <div className={'w-full min-h-screen h-screen relative '}>
            <MobileBackgroundContainer >
              <div className={' h-3/6 w-5/6 flex flex-col items-start relative top-[8rem]  '}>
                <BigTitle className={'mb-[12px]'}> Symswap Protocol </BigTitle>
                <SubTitle className={'mb-[30px]'}>Trade NFT by <span> Progressive matching</span> </SubTitle>
                <Describe>Symswap is a decentralized <span>NFT AMM</span> based on Progressive price matching for maximizing assets liquidity</Describe>

                <div className={'flex h-[50px] w-[80%] mt-[80px]'}>
                  <ButtonGroup >
                    <button className={'btn-container explore-button rounded-lg'}>Coming soon</button>
                    <button className="btn btn-gradient-border btn-glow  rounded-lg"><span>Documents</span></button>
                  </ButtonGroup>
                </div>
              </div>
            </MobileBackgroundContainer>
          </div>

      }

    </div>
  )
}

export default HomePage
